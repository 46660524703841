@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');
.main-container{
   
        /* width: 100vw; */
        height: 100%;
        min-height: 100vh;
        opacity: 1;
        color: rgb(52, 71, 103);
        display: flex;
        /* overflow-x: hidden; */
        justify-content: center;
        align-items: center;
    }

.signin-main{
    gap: 35px;
    width: 560px;
    height: 562px;
    display: flex;
    opacity: 1;
    box-shadow: 0px 0px 30px #00000029;
    align-items: center;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
}
.jss4 {
    font: normal normal 200 33px / 50px Poppins;
    color: #646464;
    opacity: 1;
    text-align: center;
    letter-spacing: 0px;
}

.jss6 {
    font: normal normal normal normal 16px / 28px Poppins;
    opacity: 1;
    text-align: center;
    letter-spacing: 0.4px;
}

.jss7 {
    gap: 40px;
    width: 369px;
    border: 1px solid #227b87;
    height: 74px;
    display: flex
;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
}
.jss7:hover {
    cursor: pointer;
    background: #227b87 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 8px 0 #0003, 0 6px 20px 0 #00000030;
    color:'white'
}
.jss7:hover .jss8{
    color: white;
}
.jss8 {
    font: normal normal 20px / 30px Poppins;
    color: #014754;
    opacity: 1;
    text-align: right;
    letter-spacing: 0px;
}
.jss9 {
    width: 36px;
    height: 36px;
}