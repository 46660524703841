.item-table-label{
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-top: 1px solid #ebeaf2; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f9f9fb;
    /* width: 95%; */
}

.item-table-label .lable-text{
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px !important;
    font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
}
.line-item-table .line-item-header{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.line-item-table .line-item-header .line-item-column {
    color: #222;
    background-color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    border-left-color:transparent;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-color: #ebeaf2;

}
.line-item-table .line-item-column {
    padding: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    vertical-align: top;
    word-wrap: break-word;
}
.line-item-table.line-item-table-light .line-item-column {
    border-color: #ebeaf2;
}
.line-item-table .bill-items .bill-desc-us {
    width: 45%;
    
}
.item-number{
    margin-top: 6px;
    margin-left: 1rem;
}
.line-item-table .bill-items .col-number {
    width: 2%;
    border-left-color:transparent;

    
}
.line-item-table .bill-items .tds-desc-us {
    width: 40%;
    border-left-color:transparent;

}
.line-item-table .bill-items .bill-acc {
    width: 15%;
}
.line-item-table .bill-items .item-qty {
    width: 15%;
}
.line-item-table .bill-items .item-rate {
    width: 15%;
}
.line-item-table .bill-items .tds-item-rate {
    width: 20%;
}
.line-item-table .bill-items .tds-item-percentage {
    width: 20%;
}
.line-item-table .bill-items .item-tax {
    width: 20%;

}
.total-label{
    font-size: 13px;
    font-weight: 600;
}
.border-bottom{
    border-bottom: 1px solid #000;
}
.line-item-table .bill-items .item-amount-us {
    border-right-color:transparent;

}
.line-item-table .bill-items .tds-amount-us {
    border-right-color:transparent;

}
.line-item-table .line-item-body {
    margin-bottom: 13px;
}
.line-item-table .line-item-body .new-line-item {
    opacity: 1;
    margin-left: -20px;
    padding-left: 20px;
}
.line-item-table .line-item-body .line-item {
    /* opacity: 0; */
    transition: opacity .3s linear;
}
.line-item-table .line-item-column .form-control {
    border: 1px solid transparent;
    box-shadow: none;
    outline: 0;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    background: transparent;
    appearance: none;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:hover {
    border: 1px solid #6ab4fa !important;
}

.line-item-body .item-amount-us {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    padding: 8px 10px;
    font-size: .9rem;

    
}
.item-description {
    border: 0;
    box-shadow: none;
    resize: none;
}
.text-end {
    text-align: right !important;
}
.add-item-btn{
    display: flex;
}
.add-item-btn .btn{
    display: flex;
    background: #f1f1fa !important;
    box-shadow: none;
    border-radius: .375rem;
    border: none;
    min-width: fit-content !important;
    cursor: pointer;
    
}
.add-item-btn .add-item-icon{
    margin-top: 2px;
    color: #2f99fc;
}
.add-item-btn .btn-text{
    margin-left: 5px;
    font-size: 14px;
    color: #000;
}
.invoice-discount{
    margin-right: 15px !important;
    margin-top: 20px !important;
}



.total-section {
    background-color: #f9f9fb !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    border-radius: 10px;
    /* height: 200px; */
    margin-bottom: 10px;
    
}
.item-actions-container {
    position: relative;

}
.item-actions-container .item-actions {
    position: absolute;
    right: -60px;
    top: -22px;
    display: flex;
}

.item-name-actions-container .item-actions {
    position: absolute;
    right: -5px;
    display: flex;
    bottom: 16px;
}
.item-content .name{
    width: 100%;
    position: relative;
}
.item-name-actions-container .item-actions .icon-btn{
    margin-right: 8px;
    cursor: pointer;
}
.item-name-actions-container .chakra-menu__menu-list{
    min-width: 140px;
}
.menu-edit-item .menulist{
    width: 200px;
}
.item-name-actions-container .item-actions .icon{
    padding: 1px;
    color: gray;
}
.digital-service{
    margin-bottom: 10px;
}
.item-name-actions-container .item-actions .iconclose{
    border: 1px solid #ccc;
    border-radius: 50%;
}
.item-content .name-action{
    /* width: 100px !important; */
    height: 15px;
}

.font-xxs {
    font-size: 11px;
}
.total-section .total-row {
    min-height: 28px !important;
}
.text-semibold{
    font-weight: 600;
}
.total-section .total-row .total-label {
    display: inline-block;
    min-height: 28px;
    width: 80%;
    padding: 10px 1%;
}
.total-section .total-row .total-amount {
    float: right;
    padding: 10px 1.5%;
    min-height: 28px;
    width: 20%;
    margin-left: -1%;
    text-align: right;
    letter-spacing: .3px;
    font-size: 13px;
}
.total-section .total-row.gross-total {
    border-right: none;
    font-weight: 600;
    font-size: 13px;
}
.border-top {
    border-top: 1px solid #ebeaf2 !important;
}


.ellipsis-btn{
    cursor: pointer;
    margin-left: 4px;

    margin-top: 6px;
    color: gray;

}
.item-close-btn{
    font-size: 15px;
    cursor: pointer;
    color: red;
    font-size: 15px;
    margin-bottom: 8px;

}
.total-label .form-control{
    box-shadow: none;
}
.adjusment-div .form-control{
    border: 1px dashed #ccc;
    font-size: .8rem;
    text-align: left;
}
.radio-btn{
    font-size: 13px !important;
}

.item-menu-popup {
    position: absolute;
    box-shadow: 0px 0px 12px #ccc;
    background-color: #fff !important;
    width: 550px;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 10px;
    padding:  0.100000in  0.100000in;
    border-radius: .375rem;
}
.item-menu-popup .menu-item-list .list-item{
    border-bottom: 1px solid #ccc;
    padding: .4rem .5rem;
    border-radius: .375rem;
    font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;

}
.item-menu-popup .menu-item-list .list-item:last-child{
    border-bottom: none;
    padding: .4rem .5rem;

}
.item-menu-popup .menu-item-list .list-item .label{
   font-size: 16px;
   height: 15px;
}
.item-menu-popup .menu-item-list .list-item .helper{
    font-size: 13px;
 }
.item-menu-popup .menu-item-list .list-item:hover{
    background: #2b8ae2;
    color: #fff !important;
    font-weight: 400 !important;
}
.menu-item-list{
    max-height: 350px;
    overflow-y: auto;
}
.item-menu-popup .no-result{
    height: 50px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-new-item-btn{
    width: 100%;
    height: 24px;
    border-top: 1px solid #ccc;
}
.add-new-item-btn .btn{
    border: none;
    display: flex;
    background:none;
    color: #408dfb;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
}
.add-new-item-btn .btn .add-item-icon{
    margin-top: 2px;
}
.add-item-form {
    padding-top: 10px;
}
.add-item-form .row{
    margin-top: 18px;
}
.add-item-form .col-3{
    width: 24%;
}
.add-item-form .form-label{
    color: #000 !important;
}

.add-item-form .form-control1,.add-item-form .custom--dropdown-container {
    height: 33px;
    box-shadow: none;
    font-size: 13px;
}

.add-item-form .custom--dropdown-container .dropdown-item:hover{
    background: #2f99fc;
}
.content-description{
    background-color: #fbfafa !important;
}
.item-content .item-tratment{
    display: flex;
    margin-top: 2px;
}
.item-content{
    padding: 2px;
    padding-top: 5px;
    padding-bottom: 10px;
}
.item-content .item-tratment .type{
    border: none;
    margin-right: 3px;
    font-size: 0.75em;
    margin-top: 6px !important;
    /* text-align: center; */
    height: 20px;
    background: #2098ff;
    font-weight: 600;
    padding: .1rem .4rem;
    color: white;
    text-transform: uppercase;
}
.item-content .item-tratment .itemcode{
    font-weight: 400;
    font-size: 11px;
    position: relative;

}
.item-content .item-tratment .itemcode .item-code-popover{
    top: 23px;
    box-shadow: none;
    padding: 10px 10px;
    color: #212529;
}
.line-item-table .line-item-column .vendor-dropdown-menu .form-control{
    border: 1px solid #f0f0f0 !important;
}

.item-content .item-tratment .itemcode .item-code-popover .label{
    font-weight: 400;
    margin-bottom: .5rem;
    font-size: .9rem;
}
.item-content .item-tratment .itemcode .item-code-popover .btn-footer .btn{
    border: 1px solid #c4c1c1;
    margin-right: 10px;
    border-radius: 0.375rem;
    outline: none;
    font-weight: 400;
    font-size: 10px;
    height: 28px;
}
.item-content .item-tratment .itemcode .item-code-popover .save-btn{
    background-color: #408dfb !important;
    border-color: #408dfb !important;
    color: #fff;
}
.item-content .item-tratment .itemcode .item-code-popover .cancel-btn{
    background-color: #ddd !important;
    border-color: #f5f5f5 !important;
    color: #000;

}
.item-content .item-tratment .itemcode .hsnvalue{
    color: #2098ff;
    cursor: pointer;
}

.item-content .name .label{
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    color: #000;
}
.item-content .name .sku-id{
    font-weight: 400;
    font-size: 11px;
}
.add-item-form .sales-information .form-check-label{
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
}   
.select-tax-options .custom--dropdown-container{
    border: none;
    border-radius: .375rem !important;
    height: 40px !important;
    
}
.select-tax-options .custom--dropdown-container .dropdown-input{
   
    border-radius: .375rem !important;
    height: 35px !important;
}
.select-tax-options .custom--dropdown-container .dropdown-item:hover{
    background: #2f99fc;

    color: #fff !important;

}
.select-tax-options .dropdown-menu{
    max-width: 220px;
    border-radius: .375rem !important;

}  
.add-item-form .item-tax-heading{
    font-size: 1.1rem;
    margin-top: 10px;
    font-weight: 400;
}
.borderBottomDash{
    border-bottom: 1px dashed gray;
}
.add-item-form .btn-footer .btn{
    border: 1px solid #c4c1c1;
    margin-right: 10px;
    border-radius: 0.375rem;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    height: 35px;
}
.add-item-form .save-btn{
    background-color: #408dfb !important;
    border-color: #408dfb !important;
    color: #fff;
}
.add-item-form .cancel-btn{
    background-color: #ddd !important;
    border-color: #f5f5f5 !important;
}
.popover-icon-button{
    border: none !important;
    outline: none !important;
    background: none !important;
    max-height: fit-content;
    min-width: 1.5rem !important;
}
.info-popover-content{
    max-width: 250px;
    padding: 4px 8px;
    background-color: #3d3e4d !important;
    color: white;
    font-size: 13px;
    outline: none !important;
    box-shadow: none !important;
}
.popover-arrow{
    background: #3d3e4d !important;
}
.tds-percentage-field{
    border: 1px solid #5ebce0 !important;
    width: 70% !important;
}
.disabled-field{
    background: #e9ecef !important ;
    border: 1px solid #f0f0f0 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
}
.gst-label{
    font-size: 10px !important;
}

.not-allow {
    cursor: not-allowed !important;
    opacity: .5;
}
.item-export-menu {
    position: relative;
        /* box-shadow: 0px 0px 12px #ccc; */    

}
.item-export-menu .export-menu-list{
    position: absolute;
    background-color: white;
    color: #000;
    right: 3rem;
    top: 1px;
    border: 1px solid #ccc;
    width: 120px;
    box-shadow: 0px 0px 12px #ccc;
    border-radius: .375rem;    
    z-index: 1;
    padding: 3px 1px;

}
.item-export-menu .menu-ellipsis-btn{
    border: none;
    color: rgb(68, 68, 68);
    cursor: pointer;
    font-size: 18px;
}
.item-export-menu .export-menu-list .export-menu-item{
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: .375rem;

    color: #000;
    text-align: center;
    
}
.item-export-menu .export-menu-list .export-menu-item:first-child{
    /* padding-bottom: 4px; */
    border-bottom: 1px solid #ccc;
}
.item-export-menu .export-menu-list .export-menu-item:hover{
   background-color: #2ea568;
   color: white;

}