:root {
    --zf-body-font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
}

.nav-text{
    color: #FFFFFF !important;
    padding-left: 8px;
    font-size: 13px;
    opacity: 0.99;
    /* line-height: 1.6; */
    font-family: var(--zf-body-font-family);



}
.sidebar-link{
    outline: none !important;
    color: #22b378;
}

.sidebar-link:visited {
    background-color: rgba(0, 0, 0, 0.1); /* Example effect */
    color: #22b378; /* Change text color on hover */
   
}
.sidebar-link:hover{
    background-color: rgba(0, 0, 0, 0.1); /* Example effect */
    color: transparent; /* Change text color on hover */
}

.nav-stack{
    margin-bottom: 1px;
    padding-left: 5px;

}
.nav-icon{
    color: #f0f0f0;
    height: 16px;
    width: 16px;
    opacity: 0.9;
    font-weight: 400;

    

}
.nav-icon1{
    color: #f0f0f0;
    height: 20px;
    width: 16px;
    opacity: 0.9;
    font-weight: 400;

    

}
.logo-container{
    background-color: #ccc !important;
}
.sidebar-main{
    background-color: #21263c;
}
.nav-item{
    padding: 8px;
    font-family: var(--zf-body-font-family);
    
}
.sidebar-footer{
    transition: width .3s ease-in-out;
    background-color: #181c2e;
    border-top: none;
    position: fixed;
    height: 35px;
    bottom: 0;
    width: 210px;    
    border-right: 1px solid #e4e4e4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: "width 0.3s ease-in-out"

}
.sidebar-footer1{
    transition: width .3s ease-in-out;
    background-color: #181c2e;
    border-top: none;
    position: fixed;
    height: 35px;
    bottom: 0;
    width: 35px;    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: "width 0.3s ease-in-out"

}
.sidebar-footer-collaps{
    width: 40px;
}
.footer-arrow-btn{
    color: white !important;
    margin-right: 1vw;
    width: 16px;
    height: 16px;
    vertical-align: baseline;
    font-size: 18px;
    font-weight: 600;
}
.active-route{
    background-color: #22b378;
    border-radius: 8px;
    margin-right: 14px;
    /* transition: background-color .3s ease; */

}
.active-route1{
    background-color: #22b378;
    border-radius: 8px;
    padding-top: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    
    /* transition: background-color .3s ease; */

}