.orginazation-profile .heading{
    padding: 0 18px;
    border-bottom: 1px solid #eee;
    height: 65px;
    flex-shrink: 0;
    overflow: visible;
    align-items: center !important;
    justify-content: space-between !important;
    display: flex;
    width: 100%;
    color: #000000 !important;
}
.orginazation-profile .orgnization-form{
    padding: 0 25px 50px;
    overflow-y: auto !important;
    position: relative;
    max-height: 88vh;
}
.orginazation-profile .orgnization-form .form-data{
    margin: 0 -20px;
    padding: 0 20px;
    max-width: 850px;
    margin-top: 20px !important;
}
.orginazation-profile .form-group{
    margin-bottom: 1rem;
    margin-top: 20px !important;

}
.orginazation-profile .logo-heading{
    color: #21263c;
    font-size: 15px;
    margin-bottom: 15px !important;
    font-weight: 400 !important;

    margin-top: 10px;

}
.orginazation-profile .input-file-box .logo-uploade{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d7d5e2;
    background-color: #fff;
    height: 80px;
    width: 250px;
    border-radius: 6px;
}
.orginazation-profile .input-file-box .file-uploade{
    padding-right: 0;
    padding-left: 0;
    border-left: 0;
    cursor: pointer;
    position: relative;
}
.orginazation-profile .input-file-box .file-uploade .ember-view{
    z-index: 8;
    opacity: 0 !important;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    font-size: 12px;
}
.orginazation-profile .input-file-box .ember-view::after{
    background-color: #fff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}
.orginazation-profile .input-file-box .file-uploade .up-icon{
    margin-right: 10px;
    font-weight: 800;
}
.orginazation-profile .input-file-box .file-uploade .text1{
    font-size: 14px;
}
.orginazation-profile .input-file-box .uploade-dis{
    padding-left: 25px !important;

}
.orginazation-profile .input-file-box .label{
    font-weight: 400;
    margin-bottom: .5rem;
}
.orginazation-profile .input-file-box .text-muted{
    width: 80%;
    font-size: 12px;
    color: #6c7184;
    margin-top: 0;
    margin-bottom: 1rem;
}
.orginazation-profile .hr-6{
    margin-top: 25px !important;
    margin-bottom: 25px !important;
    border-top: 1px solid rgba(0, 0, 0, .1);
    opacity: 1;
    margin: 20px 0;
    color: gray;
}
.orginazation-profile .form-box .col-form-label{
    flex: 0 0 auto;
    width: 25%;
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: 13px;
    line-height: 1.6;
    text-align: left;
}
.orgnization-form .form-box .row{
    margin-top: 22px;

}
.orginazation-profile .form-box .col-lg-3 span{
    color: #000000;
}
.orginazation-profile .form-box .col-lg-6 { 
        flex: 0 0 auto;
        width: 62% !important;
        padding-right: 30px;
        padding-left: 30px;
        margin-top: 0;
}
.orginazation-profile .form-box .col-lg-6 .form-control,.orginazation-profile .form-box .col-lg-6 .form-select{
    font-size: 13px !important;
    font-weight: 400;
    line-height: 1.6;
    color: #21263c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d7d5e2;
    border-radius: 6px;
    width: 100%;
    padding: .375rem .375rem;
    height: 33px;
    outline: none !important;
}
.required{
    color: #e54643 !important;
}
.orginazation-profile .form-box .col-lg-6  .input1{
    margin-right: 8px;
}   
.orginazation-profile .form-box .col-lg-6  .input2{
    margin-left: 8px;
}
.orginazation-profile .form-box .heading2{
    color: black;
    font-weight: 600;
}
.orginazation-profile .field-table tr:first-child th{
    border-top-left-radius: 6px;
}   
.orginazation-profile .field-table tr:last-child th{
    border-right: 1px solid #ebeaf2;
    
}   
.orginazation-profile .field-table th{
    color:#6c7184;
    border-top: 1px solid #ebeaf2 !important;
    vertical-align: bottom;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f9f9fb;
    border-left: 1px solid #ebeaf2;
    border-bottom: 1px solid #ebeaf2 !important;
    padding: .5rem .5rem;
    width: 38%;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    font-size: 11px;
    border-radius: 6px;
}
.orginazation-profile .field-table td{
    padding: 0;
    /* border-left: 1px solid #ebeaf2;
    border-bottom: 1px solid #ebeaf2; */
    vertical-align: middle;
    border: 1px solid #ebeaf2;
    color: #000000;

}
.orginazation-profile .field-table td .form-control{
    border: 1px solid transparent;
    box-shadow: none;
    color: #4c526c;
    padding: 5px 8px;
    min-height: 34px;
    font-size: 13px;

}
.orginazation-profile .btn-footer .btn{
    border: 1px solid #c4c1c1;
    margin-right: 10px;
    border-radius: 0.375rem;
    outline: none;
    font-size: 12px;
}
.orginazation-profile .save-btn{
    background-color: #408dfb !important;
    border-color: #408dfb !important;
    color: #fff;
}
.orginazation-profile .cancel-btn{
    background-color: #ddd !important;
    border-color: #f5f5f5 !important;
}
.file-name{
    color: rgb(13, 47, 240);
    margin-top: 2px;
    margin-left: 5px;
    font-size: 16px;
}
.action-heading,.action-data{
    width: 100px !important;
    padding-left: 40px !important;
}

.menu-bar {
    display: flex;
    background-color: #333;
    padding: 10px;
    align-items: center;
}

.menu-item {
    color: white;
    padding: 14px 20px;
    text-decoration: none;
    cursor: pointer;
}

.menu-item:hover {
    background-color: #575757;
}

.dropdown {
    position: relative;
    display: inline-block;
}
.terms_textarea{
    font-size: 13px;
    box-shadow: none !important;
}
.label{
    font-size: 13px;
    color: #000000;
}