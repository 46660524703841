.purchase-order-pdf{
    position: relative;
    box-shadow: 0px 0px 12px #ccc;    
    background-color: #fff !important;
    width: 840px;
    /* padding:  0.200000in  0.450000in; */
    margin-bottom: 20px;
    padding:  0.400000in  0.300000in;
    margin-top: 3rem;
}
.payment-pdf-info-modal{
    box-shadow: 0px 0px 12px #ccc !important; 

}
.purchase-order-pdf .payment-pdf-logo{
    max-width: 130px;
    /* background-color: #000000; */
    margin-left: 1.5rem;
    border-radius: 3px;
    /* margin-top: 20px; */
    height: 115px;
    
}
.purchase-order-pdf .vendor-address-box{
    margin-top: 2rem;
    display: flex;
}
.purchase-order-pdf .vendor-address-box .order-details{
    position: relative;
    margin-top: 2rem;
    font-size: 8pt !important;
    margin-left: 8rem;
    /* width: 200px; */
}
.purchase-order-pdf .vendor-address-box .order-details-list{
   display: flex;
   margin: .5rem;
}   

.purchase-order-pdf .vendor-address-box .order-details-list .list-heading{
    width: 100px;
    text-align: end;
    margin-right: .5rem;

}  
.purchase-order-pdf .vendor-address-box .order-details-list .list-value{
    width: 130px;
    text-align: end;
    margin-left: 1rem;
}  
.purchase-order-header .top-heading{
    position: relative;
    margin-left: 24rem;
    margin-top: 1rem;
}
.purchase-order-header .top-heading .heading-text{
 font-size: 2rem;
 padding: 0;
max-height: 33px;
}
.purchase-order-header .top-heading .poNo-text{
    padding: 0 !important;
    font-weight: 600;

}
.purchase-order-pdf .payment-pdf-header .title{
    font-weight: 600;
    font-size: 10pt;
    text-transform: uppercase !important;
}
.payment-template .close-btn{
    position: absolute;
    top: .8rem;
    right: 1rem;
    font-size: 18px;
    background-color: none;
    color: red;
}
.payment-template .pdf-downlode-icon{
    position: absolute;
    top: .8rem;
    right: 3rem;
    font-size: 18px;
    background-color: none;
}
.terms-condtions{
    font-size: 8pt;
    margin-top: 5px;
    margin-left: 1rem;
}
.terms-condtions .terms-text{
    margin: 0;
}
.pdf-footer{
    position: relative;
    font-size: 9pt;
    text-align: center;
    color: #747474;
    height: 50px;
    margin-top: 10rem;

}
.pdf-footer .footer-text{
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem !important;

}
.pdf-footer .footer-divider{
    height: 1px;
    margin-bottom: 1.5px;
}
.payment-template .print-downlode-icon{
    position: absolute;
    top: .8rem;
    right: 5rem;
    font-size: 18px;
    background-color: none;
}
.purchase-order-pdf .payment-pdf-header .address{
    font-weight: 400;
    font-size: 8pt;
    margin-top: 0px !important;
}


.purchase-order-pdf .amount-div{
    text-align: center;
    color: #ffffff;
    float: right;
    background: #78ae54;
    width: 25%;
    padding: 34px 5px;
    font-size: 13pt;
}
.purchase-order-pdf .amount-div .pcs-total{
    font-size: 16pt !important;
    color: #ffffff;

}

.purchase-order-pdf .payment-table-box{
    margin: 1rem;
    margin-top: 1rem;
}

.payment-table-box table{
    width: 100%;
    table-layout: fixed;
    caption-side: bottom;
    border-collapse: collapse;

}
.payment-table-box table thead{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.payment-table-box table thead tr{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    height: 40px;
}
.payment-table-box table thead tr th{
    padding: 10px 8px 10px 20px;
    word-wrap: break-word;
    font-size: 8pt;
    vertical-align: top;
    
    color: #fff;
    background-color: #313131;

    /* border-width: 10; */
    text-transform: capitalize;
    font-weight: 400;
    border-style: none !important;
    border-color:  #313131!important;
   

}
.payment-table-box table tbody tr{
    border-top: 1px solid #ededed;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-bottom: 1px solid #b3b0b0;
}
.payment-table-box table tbody tr td{
    padding: 5px 10px 10px 20px;
    word-wrap: break-word;
    font-size: 9pt;
    vertical-align: top;
    /* display: flex; */
    /* flex-direction: column; */
    
    background-color: #ffffff;
    color: #333333;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.display_flex{
    display: flex;
    flex-direction: row;
}
.payment-table-box table tbody tr td.item-data .name{
    font-size: 9pt;
    line-height: 1.2rem;
    font-weight: 600;
}
.payment-table-box table tbody tr td.item-data .description{
    font-size: 8pt;
    line-height: 1.1rem;
    max-width: 90%;

    margin: 0 !important;
}
.total-details{
    font-size: 8pt;
}

.total-details .total-amount-details{
    display: flex;
    justify-content: space-around;
}
.total-details .total-amount-details:not(:first-child){
    margin-top: 1rem;

}
.total-details .total-amount-details .heading{
    text-align: end !important;
    width: 70px
}
.total-details .total-amount-details .value{
    text-align: end !important;
    width: 80px
}

.total-amount-details .text-bold{
    font-weight: 600;
}