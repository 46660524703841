/* CustomDropdown.css */
.custom-dropdown {
    position: relative;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid #ccc; */
    padding: 8px;
    border-radius: 4px;
  }
  .ledger-year-select{
    justify-content: flex-start;
  }
  .date-icon {
    margin-right: 8px;
   
  }
  .year-input{
    border: none !important;
    outline: none;
  }
  
  .custom-dropdown-box{
    display: block;
    right: auto;
    margin-top: 8px;
    
    padding-right: 10px;
    margin: .125rem 0 0;
    padding: 4px;
    box-shadow: 0 7px 12px 0 #d7d5e2;;
    position: absolute;
    background: #fff;
    height: 350px;
    z-index: 1;
    display: flex ;
    
  }
  .custom-dropdown-box::before{
      position: absolute;
      top: -7px;
      left: 9px;
      display: inline-block;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #b30d0d;
      border-left: 7px solid transparent;
      border-bottom-color: rgba(7, 71, 247, 0.2);
      content: '';
      left: 9px;
      right: auto;
  }
  .custom-dropdown-box .dropdown-list .listItem{
    margin-left: -5px;
    margin-right: -5px;
    padding: 6px 30px;
    text-align: center;
    height: 35px;
    align-items: center;
    display: flex ;
    justify-content: center;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;

  }
  .active-item{
    background-color: #2098ff;
  }
  .listItem:hover {
    background-color: #f0f0f0; /* Highlight on hover */
  }
  .ledger-date-renge{
    margin: 5px;
    margin-left: 80px;
    margin-right: 20px;
  }
  .ledger-date-renge .date-picker{
    margin-top: 10px;
    border: 1px solid ;
    outline: none !important;
    width: 170px;
    margin-right: 20px;
    padding: 3px 3px;
    border-radius: 8px;
  }
  .renge-button{
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .renge-button .btn{
    margin-right: 5px;
    font-size: 12px;
    background: none;
    color: #000;
    border: none;
    width: 80px;
  }
  .renge-button .btn.apply{
    background: #5aa6e9 !important;
  }
 